(function () {

  if (!document.body.classList.contains('works-single')) return this;

  const switchButton = document.querySelector('.color-btn');

  switchButton.addEventListener('click', function (e) {
    if (document.body.classList.contains('chenge-white')) {
      document.body.classList.remove('chenge-white');
    } else{
      document.body.classList.add('chenge-white');
    }
    e.stopImmediatePropagation();
  });

})();
