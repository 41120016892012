import { tns } from 'tiny-slider/src/tiny-slider';
import MicroModal from 'micromodal';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

(function () {

  if (!document.body.classList.contains('works-single')) return this;
  const live_camera_slider = tns({
    autoHeight: true,
    autoplay: false,
    autoplayButtonOutput: false,
    container: '.modal__content .gallery',
    items: 1,
    controlsText: ['<span class="prev"></span>','<span class="next"></span>'],
    controlsPosition: 'bottom',
    loop: true,
    nav: false,
    mode: ('ontouchstart' in window ? 'carousel' : 'gallery'),
    slideBy: 'page',
    speed: ('ontouchstart' in window ? 500 : 0),
  });
  const prev_button = document.querySelector('button[data-controls="prev"]');
  const next_button = document.querySelector('button[data-controls="next"]');
  const gallery_item = document.querySelectorAll('.gallery-box > .gallery > .gallery-item');
  const controls = document.querySelector('.tns-controls');
  const current = document.querySelector('.current-number');
  const total = document.querySelector('.total-number');

  MicroModal.init();

  const setCurrentNumber = (current_number) => {
    current.innerText = current_number;
    if(total.innerText === '0') total.innerText = gallery_item.length;
  };

  gallery_item.forEach((item, i) => {
    item.addEventListener('click', (e) => {
      MicroModal.show('modal-1',{
        onShow: modal => disablePageScroll(modal),
        onClose: modal => enablePageScroll(modal),
      });
      live_camera_slider.goTo(i);
      setCurrentNumber(i+1)
    });
  });

  live_camera_slider.events.on('indexChanged', (info, eventName) => {
    setCurrentNumber(info.displayIndex);
  });

  if( 'ontouchstart' in window ){
   document.body.classList.add('agent-touch');
  }

})();
