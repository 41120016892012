import jQuery from 'jquery';
import Swiper from 'swiper';

const $ = jQuery;

//新規ウィンドウ
$.fn.blankWindow=function(){
	$(this).addClass('externalLink').attr('target','_blank');
}

$(function(){
//
	var page = $('#container');
//
// 	//新規ウィンドウ
	var noBlank	= [
		'[href^="http://yumakano.com/"]',
		'[href^="http://www.yumakano.com/"]',
		'[href^="https://yumakano.com/"]',
		'[href^="https://www.yumakano.com/"]',
		'[href^="http://yumakano.lc/"]',
		'[href^="http://v2.yumakano.com/"]',
		'[href^="https://v2.yumakano.com/"]',
		'[href^="http://yumakano.repair.lc/"]',
	];
	$('a[href^="https://"],a[href^="http://"]:not('+noBlank+')').blankWindow();
	$('a[href$=".pdf"],a[href^="mailto:"]').blankWindow();
//
	$('body').addClass('loaded');
//
// 	//scroll
	// if(Modernizr.touch){
	// 	// set to the main content of the page
	//   $(window).mousewheel(function(event, delta, deltaX, deltaY){
	// 		var speed = 1.5;
	// 		page.scrollTop( page.scrollTop() - delta * speed );
	// 		if (event.preventDefault) event.preventDefault();
	// 		event.returnValue = false;
	//   })
	// }
// 	//戻る出現判定
	$(window).on("scroll", function(){
		var view_height = $(window).height();
		var scroll_y = $('html').scrollTop();
		if(scroll_y >= 400 ){
			$('body').addClass('is_flow-first');
		}else{
			$('body').removeClass('is_flow-first');
		}
	});
	//toTop
	$('#totop').on('click',function(){
		$('html,body').stop().animate({'scrollTop': 0 }, 600, 'swing');
		return false;
	});
//
// 	//jpg拡大
	// $('a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".gif"]').each(function(index, element){
	// 	$(this)({
	// 		maxWidth:'100%', maxHeight:'95%',
	// 		transition:'fade',
	// 		title:$(this).children('img').attr('alt'),
	// 		rel:'gallery'
	// 	});
	// });
//
//
	var $hamburger = $(".hamburger");
	var $modal__close = $(".modal__close");
  $hamburger.on("click", function(e) {
			$hamburger.toggleClass("is-active");
			$('body').toggleClass("nav-is-active");
  });
	$modal__close.on("touchstart",function(e){
		e.preventDefault()
	});
	//
//
//
	//ギャラリーのサムネイルパラパラアニメ
	var force = 0,
      touch = null;

			// イベントをキャンセル
		  function cancelEvent(e){
		    e.preventDefault();
		    e.stopPropagation();
		  }

		  // タッチを取得して、値の更新を実行する
		  function checkForce(e){
		    cancelEvent(e);
		    touch = e.touches[0];
		    refreshForceValue();
		  }

		  // タップの強さを更新する
		  function refreshForceValue(){
		    if( !touch ) return;
		    force = touch.force;
				console.log(force);
		    setTimeout(refreshForceValue, 10);
		  }
			// .on("touchstart", checkForce, false)
			// .on("touchmove", checkForce, false)
			// .on("touchend", function(e){
			// 	cancelEvent(e);
			// 	touch = null;
			// 	if(fouce > 10) this.is_move_link = true;
			// 	force = 0;
			// }, false)

	$('#projects>.gallery-thumb>a,#projects_gallery>.gallery-thumb>a')
		.each(function(){
			this.is_move_link = false;
			this.hover = false;
			this.count = 0;
			this.$list = $(this).find('li');
			this.i=0;
		})
		.on('moveToLink',function(event) {
			location.href = $(this).attr('href');
			this.is_move_link = false;
			return false;
		})
		.on('moveToLinkOff',function(event) {
			this.is_move_link = false;
		})
		.on('touchstart',function(event) {
			this.is_touch = true;
			$('#projects>.gallery-thumb>a,#projects_gallery>.gallery-thumb>a').not(this).trigger('moveToLinkOff');
		})
		.on('touchend',function(event) {
			$(this).trigger('mouseout');
		})
		.on('click',function(event) {
			if(this.is_touch && !this.is_move_link ){
				this.is_move_link = true;
				$(this).trigger('mouseover');
			}else{
				// console.log(this.is_move_link);
				$(this).trigger('mouseout');
				$(this).trigger('moveToLink');
			}
			return false;
		})
		.on('mouseover',function(event) {
			if(this.hover == event.currentTarget) return false;
			this.hover = event.currentTarget;
			this.isLoopChange = true;
			if(this.isLoopChange){ $(this).trigger('changeLayer'); }
		})
		.on('mouseout',function(event) {
			//if(Modernizr.touchevents) return this;
			if($(event.toElement).parents('a').get(0) == $(this)[0]) return this;
			this.hover = false;
			this.isLoopChange = false;
		})
		.on('changeLayer',function() {
			if(!this.isLoopChange) return this;
			this.count >= this.$list.length-1 ? this.count = 0 : this.count++;
			this.$list.css({zIndex:0})
				.filter(':eq('+this.count+')').css({zIndex:1});
			$(this).stop(false,true).animate({ opacity: 1 }, 250, //3000+Math.random()*3000,
				function() {
					if(this.isLoopChange) $(this).trigger('changeLayer');
				});
		}).find('li').each(function(index, el) {
			var max = 10, min = -10;
			var randomT = Math.random() * (max - min) + min;
			var randomL = Math.random() * (max - min) + min;
			$(this).css({
				top:randomT/2+"%",
				left:randomL+"%",
			})
			// console.log(randomT,randomL);
		});

		$('#media').find('.media-post__link')
			.each(function(){
				this.is_move_link = false;
			})
			.on('moveToLink',function(event) {
				location.href = $(this).attr('href');
				return false;
			})
			.on('moveToLinkOff',function(event) {
				this.is_move_link = false;
				$(this).removeClass('hover');
			})
			.on('mousehover',function(event) {
				this.is_move_link = true;
				$(this).addClass('hover');
				return false;
			})
			.on('touchstart',function(event) {
				this.is_touch = true;
				$('#media').find('.media-post__link').not(this).trigger('moveToLinkOff');
			})
			.on('touchend',function(event) {
				//$(this).trigger('mouseout');
			})
			.on('click',function(event) {
				console.log('click');
				console.log(this.is_move_link);
				if(this.is_touch && !this.is_move_link){
					$(this).trigger('mousehover');
				}else{
					$(this).trigger('moveToLinkOff');
					$(this).trigger('moveToLink');
				}
				return false;
			});
//
// 	//トップページのスライド
	if($('#projects').length){
		var mySwiper = new Swiper('#mainSlider', {
			speed: 2000,
			autoplay: { delay: 3000 },
			loop: true,
			effect: "fade",
		  fadeEffect: { crossFade: true }
		});
		function scroll_control(){
			$('#slider_switch').trigger('coverClose');
			document.removeEventListener("mousewheel", scroll_control, {passive: false});
		 	document.removeEventListener("touchmove", scroll_control, {passive: false});
		}
		document.addEventListener("mousewheel", scroll_control, {passive: false});
	 	document.addEventListener("touchmove", scroll_control, {passive: false});

		$('#slider_switch').on('coverClose',function(){
			if($('#mainSlider').hasClass('closed-cover')) return this;
			$('#mainSlider, #projects_gallery, #container, #container-content').addClass('closed-cover');
			$('#mainSlider').delay(1000).queue(function () {
	      $(this).hide();
	    });
		}).on('click',function() {
			$(this).trigger('coverClose');
		});

		$('#slider_switch').on('coverClose',function(){
			if($('#mainSlider').hasClass('closed-cover')) return this;
			$('#mainSlider, #projects_gallery, #container, #container-content').addClass('closed-cover');
			$('#mainSlider').delay(1000).queue(function () {
				$(this).hide();
			});
		}).on('touchstart',function() {
			$(this).trigger('coverClose');
		});

	}//end $('#projects')

});
