import ScrollOut from "scroll-out";

(function () {

  ScrollOut({
    targets: ".passing",
    offset: 0
  });

})();
